































































































































































import { Api, TableState, CountryInfo, Locale, RosterType } from '@/edshed-common/api'
import Component from 'vue-class-component'
import ComponentHelper from '@/mixins/ComponentHelper'
import { Mixins } from 'vue-property-decorator'
import { SubscriptionPlanCurrency } from '@/edshed-common/subscriptionPackages'
import _ from 'lodash'
import { DictionaryLanguage, getLanguageName, getLocaleEmojiFlag, getVoiceName, VoiceLocale } from '@/edshed-common/i18n'
import RosterOptionsMenu from './components/RosterOptionsMenu.vue'

@Component({
  name: 'Countries',
  components: { RosterOptionsMenu }
})
export default class Countries extends Mixins(ComponentHelper) {
  loading: boolean = false

  originalCountries: CountryInfo[] = []
  countries: CountryInfo[] = []

  SubscriptionPlanCurrency = SubscriptionPlanCurrency

  Locale = Locale

  searchTerm: string = ''

  table: TableState = {
    page: 1,
    perPage: 10,
    sort: 'id',
    dir: 'asc',
    term: ''
  }

  RosterType = RosterType

  getLanguageName = getLanguageName
  getVoiceName = getVoiceName
  getLocaleEmojiFlag = getLocaleEmojiFlag

  DictionaryLanguage = DictionaryLanguage
  VoiceLocale = VoiceLocale

  async mounted () {
    this.originalCountries = await Api.getCountries()
    this.countries = _.cloneDeep(this.originalCountries)
  }

  get filteredCountries () {
    if (this.searchTerm.trim() === '') {
      return this.countries
    }

    const term = this.searchTerm.toLowerCase()

    return this.countries.filter(c => c.name.toLowerCase().includes(term) || c.code.toLowerCase().includes(term))
  }

  doesCountryNeedSave (country: CountryInfo) {
    const original = this.originalCountries.find(c => c.code === country.code)

    if (!original) {
      throw new Error(`Country with code ${country.code} not in original list`)
    }

    return !_.isEqual(country, original)
  }

  getRosterOptionsArray (country: CountryInfo) {
    return country.roster_options.map(o => o.type)
  }

  rosterOptionToggled (country: CountryInfo, option: RosterType) {
    if (country.roster_options.find(o => o.type === option)) {
      country.roster_options = country.roster_options.filter(o => o.type !== option)
    } else {
      country.roster_options.push({
        type: option,
        subscription_requirement: ['full'],
        org_requirement: ['school', 'district']
      })
    }
  }

  async saveCountry (country: CountryInfo) {
    try {
      const newCountry = await Api.editCountry(country.code, {
        default_currency: country.default_currency,
        accepted_currencies: country.accepted_currencies,
        tax_rate: country.tax_rate,
        default_language: country.default_language,
        default_voice: country.default_voice,
        reverse_charge: country.reverse_charge,
        roster_options: country.roster_options
      })

      const original = this.originalCountries.find(c => c.code === country.code)!

      Object.assign(original, _.cloneDeep(newCountry))
      this.$buefy.toast.open({
        message: 'Details updated',
        position: 'is-bottom',
        type: 'is-success'
      })
    } catch (err: unknown) {
      if (err instanceof Error) {
        this.$buefy.toast.open({
          message: 'Could not save country',
          position: 'is-bottom',
          type: 'is-danger'
        })
      }
    }
  }
}

